<template>
  <div class="grid grid-cols-2 w-full h-full tab">
    <div
      class="tab__item"
      v-for="(tab, index) in tabsList"
      :key="index"
      @click="handleClick(tab.key)"
      :class="{ selected: isSelected === tab.key }"
    >
      <span class="text-md md:text-xl lg:text-2xl font-normal mb-0 text-inherit">{{ tab.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabsList: {
      type: Array,
      required: true,
    },
    defaultSelect: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSelected: this.defaultSelect,
    };
  },
  methods: {
    handleClick(value) {
      this.isSelected = value;
      this.$emit("handleEvent", value);
    },
  },
};
</script>